
@font-face{font-family:PicNic-Regular;src:url("./assets/fonts/PicNic-Regular.woff2") format("woff");font-weight:400;font-style:normal;font-display:swap}body{margin:0}

@font-face {
    font-family: Authentic;
    src: url("./assets/fonts/AUTHENTICSans-60.woff2") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

p, * {
  font-family: Authentic;
  line-height: 1.3;
}

.css-ahj2mt-MuiTypography-root {
   font-family: Authentic !important;
}


h1 {
  font-size: 3.5em;
  font-family: PicNic-Regular;
}

h2 {
  font-size: 2.7em;
  font-family: PicNic-Regular;
  margin-top: 0;
}

h3 {
  font-size: 2.4em;
  font-family: PicNic-Regular;
  margin-bottom: 10px;
}

a {
  text-decoration: none;
  color: unset;
}

.logo {
  width: 250px;
  max-width: 90%;
  margin-left: 10%;
  margin-top: 20px;
}

.header {

}

.textContainer {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.spenden {
  position: absolute;
  width: 200px;
  margin-left: 450px;
  margin-top: -120px;
}

.spenden p {
  position: absolute;
  margin-top: -65px;
  margin-left: 45px;
  font-weight: bold;
}

@media (max-width: 760px) {
  .spenden {
    position: sticky;
    display: block;
    top: 0;
    right: 20px;
    margin-left: calc(80% - 80px);
    z-index: 1000;
  }
}

@media (max-width: 750px) {
  .spenden {
    position: sticky;
    display: block;
    right: 20px;
    margin-top: -200px;
    margin-bottom: 100px;
    margin-left: calc(80% - 100px);
    z-index: 1000;
  }
}

@media (max-width: 527px) {
  .spenden {
    position: sticky;
    display: block;
    right: 20px;
    margin-top: -260px;
    margin-bottom: 130px;
    margin-left: calc(80% - 100px);
    z-index: 1000;
  }

  .textContainer {
    margin-top: 75px;
  }
}

.ziel {
  font-size: 3em;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  z-index: 100;
}

.barContainer {
  height: 300px;
  vertical-align: middle;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  overflow: hidden;
}

.bar {
  background-color: #fff;
  border: 2px solid black;
  width: 80%;
  margin: auto;
  margin-top: 48px;
  height: 60px;
}

.bar h2 {
  position: absolute;
  width: 80%;
  margin-top: 0px;
  text-align: center;
  color: white;
  color: #fff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000
}

.filled {
  background-color: green;
  background-size: cover;
  height: 100%;
  border-right: 2px solid black;
}

.status {
  text-align: center;
}

.twint {
  background-color: white;
  height: 175px;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  text-align: center;
  overflow: hidden;
}

.bank {
  background-color: white;
  height: 175px;
  border-bottom: 2px solid black;
  text-align: center;
  overflow: hidden;
}

.twint h2, .bank h2 {
  font-size: 2.7em;
  text-align: left;
  position: relative;
  top: -175px;
  left: 20px; 
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff
}

.twint:hover h2, .bank:hover h2 {
  color: white;
  color: #fff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000
}

.twint .movingBackground, .bank .movingBackground, .twint .movingBackground img, .bank .movingBackground img {
  background-size: cover;
  display: flex;
  height: 175px;
  position: relative;
  height: 100%;
}

.twint:hover .movingBackground, .bank:hover .movingBackground{
    transform: translate3d(1%, 0px, 0px) scale3d(1, 1, 1);
    transform-style: preserve-3d;
    transition: all 1s;
    will-change: transform;
    animation: my-animation 18s linear infinite;
}

@keyframes my-animation {
  from {
   /* -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);*/
    transform: translateX(0%);
  }
  to {
    /*-moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);*/
    transform: translateX(-300%);
  }
}

.faq {
  margin-bottom: 90px;
}

.Accordion {
  width: 70%;
  margin-left: 15%;
  margin-top: -10%;
}

.img {
  max-width: 100%;
}

.faq h2 {
  font-size: 6em;
  transform: rotate(270deg);
  text-align: left;
  display: inline-block;
  margin-top: 40px
}


@media(max-width: 960px) {
  .Accordion {
    width: 80%;
    margin-left: 10%;
    margin-top: 0px;
  }
}

@media(max-width: 900px) {
  .twint {
    border-right: none;
  }

  .faq h2 {
    margin-left: 5%;
    font-size: 6em;
    transform: rotate(0deg);
    text-align: left;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 18px;
  }
}


.footer {
  border-top: 2px solid black;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}
